/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { RiPrinterLine, RiDownloadLine, RiArrowLeftLine, RiCheckLine, RiUserLine, RiStarLine, RiLineChartLine } from "react-icons/ri"

const StandardReviewTemplateDownloadPage = () => {
  
  // Function to handle printing the template
  const handlePrint = () => {
    if (typeof window !== 'undefined') {
      window.print();
    }
  }
  
  return (
    <Layout>
      <SEO 
        title="Standard Performance Review Template | Comprehensive Employee Evaluation Tool"
        description="Download our standard performance review template for comprehensive employee evaluations. Assess performance against goals, competencies, and identify development areas."
        keywords={[
          "performance review template",
          "employee evaluation form",
          "annual review template",
          "performance assessment tool",
          "employee appraisal form",
          "competency evaluation template",
          "performance management tool",
          "employee feedback form",
          "goal achievement assessment",
          "development planning template"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <Link 
              to="/guides/performance-reviews" 
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                color: 'primary',
                fontSize: '0.9rem',
                textDecoration: 'none',
                mb: 3,
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              <RiArrowLeftLine sx={{ mr: 1 }} /> Back to Performance Reviews Guide
            </Link>
            
            <h1 sx={{
              fontSize: ['1.8rem', '2.2rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              Standard Performance Review Template
            </h1>
            
            <p sx={{
              fontSize: '1rem',
              lineHeight: 1.5,
              mb: 4,
              color: 'text',
              opacity: 0.9
            }}>
              A comprehensive evaluation tool for assessing employee performance against goals, competencies, 
              and development areas to drive improvement and recognize achievements.
            </p>
            
            <div sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 3,
              mb: 4
            }}>
              <button 
                onClick={handlePrint}
                type="button"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                  }
                }}
              >
                <RiPrinterLine /> Print Template
              </button>
              
              <Link 
                to="/contact"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  border: '2px solid',
                  borderColor: 'primary',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}
              >
                <RiDownloadLine /> Request Full Template
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      {/* Main content */}
      <div className="printable-content" sx={{
        maxWidth: '1200px',
        mx: 'auto',
        px: 3,
        py: [4, 5],
        '@media print': {
          padding: '0',
          fontSize: '12pt'
        }
      }}>
        <div sx={{
          maxWidth: '800px',
          mx: 'auto',
          bg: 'white',
          p: [3, 4],
          borderRadius: '8px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
          '@media print': {
            boxShadow: 'none',
            padding: '0'
          }
        }}>
          {/* Introduction Section */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Introduction to Performance Reviews
            </h2>
            <p sx={{ mb: 3, lineHeight: 1.6 }}>
              Standard performance reviews provide a structured approach to evaluating employee performance, 
              identifying strengths and growth areas, and setting goals for future development. 
              This template offers a comprehensive framework to conduct effective performance assessments.
            </p>
            <p sx={{ lineHeight: 1.6 }}>
              Regular performance reviews help organizations align individual performance with organizational 
              objectives, recognize achievements, provide constructive feedback, and create targeted development 
              plans that benefit both the employee and the organization.
            </p>
          </section>

          {/* Template Components */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Key Template Components
            </h2>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
              gap: 4,
              mb: 4
            }}>
              {[
                {
                  title: "Goal Achievement",
                  description: "Evaluation of performance against previously established goals and objectives",
                  icon: <RiLineChartLine />
                },
                {
                  title: "Competency Assessment",
                  description: "Review of key skills, behaviors, and knowledge areas relevant to the role",
                  icon: <RiStarLine />
                },
                {
                  title: "Strengths & Development Areas",
                  description: "Identification of employee's strongest attributes and areas for improvement",
                  icon: <RiUserLine />
                },
                {
                  title: "Future Goals & Development Plan",
                  description: "Clear objectives and action steps for the next performance period",
                  icon: <RiLineChartLine />
                }
              ].map((component) => (
                <div 
                  key={component.title}
                  sx={{
                    p: 3,
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: 'muted',
                    bg: '#f8f9fa'
                  }}
                >
                  <div sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mb: 2,
                    color: 'primary' 
                  }}>
                    <span sx={{ fontSize: '1.5rem', mr: 2 }}>{component.icon}</span>
                    <h3 sx={{ fontSize: '1.2rem', fontWeight: 600, m: 0 }}>
                      {component.title}
                    </h3>
                  </div>
                  <p sx={{ fontSize: '0.95rem', lineHeight: 1.6, m: 0 }}>
                    {component.description}
                  </p>
                </div>
              ))}
            </div>
          </section>
          
          {/* Template Preview */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Template Preview
            </h2>
            
            <div sx={{
              border: '1px solid',
              borderColor: 'muted',
              borderRadius: '6px',
              p: 3,
              mb: 4
            }}>
              <h3 sx={{ 
                fontSize: '1.2rem', 
                fontWeight: 700,
                mb: 3,
                color: 'text'
              }}>
                Performance Rating Scale
              </h3>
              
              <table sx={{
                width: '100%',
                borderCollapse: 'collapse',
                mb: 4
              }}>
                <thead>
                  <tr sx={{
                    borderBottom: '2px solid',
                    borderColor: 'muted'
                  }}>
                    <th sx={{ 
                      p: 2, 
                      textAlign: 'left',
                      width: '120px'
                    }}>
                      Rating
                    </th>
                    <th sx={{ 
                      p: 2, 
                      textAlign: 'left' 
                    }}>
                      Description
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[
                    { rating: "5 - Exceptional", description: "Consistently exceeds all expectations and goals. Demonstrates outstanding performance and makes significant contributions." },
                    { rating: "4 - Exceeds", description: "Frequently exceeds expectations and goals. Performance is consistently strong and effective." },
                    { rating: "3 - Meets", description: "Consistently meets expectations and goals. Performance is reliable and steady." },
                    { rating: "2 - Developing", description: "Partially meets expectations and goals. Performance requires improvement in certain areas." },
                    { rating: "1 - Unsatisfactory", description: "Consistently fails to meet expectations and goals. Significant improvement is required." }
                  ].map((item, index) => (
                    <tr 
                      key={item.rating}
                      sx={{
                        borderBottom: '1px solid',
                        borderColor: 'muted',
                        bg: index % 2 === 0 ? 'white' : '#f8f9fa'
                      }}
                    >
                      <td sx={{ p: 2, fontWeight: 600 }}>
                        {item.rating}
                      </td>
                      <td sx={{ p: 2 }}>
                        {item.description}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              
              {/* Additional template content preview */}
              <div sx={{ 
                bg: '#f8f9fa',
                p: 3,
                borderRadius: '6px',
                textAlign: 'center',
                border: '1px dashed',
                borderColor: 'muted',
                fontSize: '0.9rem',
                color: 'text',
                opacity: 0.8
              }}>
                The complete template includes sections for goal achievement assessment, competency 
                evaluation, strengths and development areas, performance summary, and development planning. 
                Request the full template to access all content.
              </div>
            </div>
          </section>
          
          {/* Benefits Section */}
          <section>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Benefits of Using This Template
            </h2>
            
            <ul sx={{ pl: 0, listStyleType: 'none' }}>
              {[
                "Provides a structured framework for comprehensive performance evaluation",
                "Balances quantitative goal achievement with qualitative competency assessment",
                "Creates a documented record of performance discussions and agreements",
                "Ensures consistent evaluation standards across the organization",
                "Facilitates meaningful development conversations between managers and employees",
                "Helps identify top performers and those requiring additional support",
                "Supports data-driven decisions about promotions, compensation, and development"
              ].map((benefit) => (
                <li 
                  key={benefit}
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    mb: 3
                  }}
                >
                  <span sx={{ color: 'primary', mr: 2, pt: '3px' }}>
                    <RiCheckLine />
                  </span>
                  <span sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                    {benefit}
                  </span>
                </li>
              ))}
            </ul>
            
            <div sx={{
              bg: '#f8f9fa',
              p: 3,
              mt: 4,
              borderRadius: '6px',
              border: '1px solid',
              borderColor: 'muted',
              fontSize: '0.95rem',
              fontStyle: 'italic'
            }}>
              <strong>Best Practice:</strong> Performance reviews should be part of an ongoing performance 
              management process, not just an annual event. Regular check-ins and feedback throughout the 
              year make the formal review more effective and prevent surprises.
            </div>
          </section>
          
          {/* Print styles */}
          <style jsx>{`
            @media print {
              body {
                font-size: 12pt;
                color: #000;
              }
              h1, h2, h3, h4, h5, h6 {
                break-after: avoid;
              }
              ul, ol, table {
                break-inside: avoid;
              }
              a {
                text-decoration: none;
                color: #000;
              }
              .printable-content {
                padding: 0;
              }
            }
          `}</style>
        </div>
      </div>
    </Layout>
  )
}

export default StandardReviewTemplateDownloadPage 